
import { Container, Row, Col } from 'react-bootstrap';
import './PrimaryActions.css';

function PrimaryActions(props) {
    return (
        // <Container>
            <Row className="justify-content-center px-4">
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="tel:+48 539 402 020">
                        <div className="img-fluid">
                            <img src="./mobile1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Mobile</div> */}
                        </div>
                    </a>
                </Col>
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="tel:+48 225 918 900">
                        <div>
                        <img src="./office1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Office</div> */}
                        </div>
                    </a>
                </Col>
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="mailto:info@ortoline.eu">
                        <div>
                        <img src="./email1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Email</div> */}
                        </div>
                    </a>
                </Col>
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="https://goo.gl/maps/5bzy3dhQ82getVd9A">
                        <div >
                        <img src="./map1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Map</div> */}
                        </div>
                    </a>
                </Col>
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="http://www.ortoline.eu">
                        <div >
                        <img src="./website1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Website</div> */}
                        </div>
                    </a>
                </Col>
            </Row>
        // </Container>
    )
}

export default PrimaryActions;