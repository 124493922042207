
import "./CardHeader.css"
import {Container, Row, Col } from 'react-bootstrap'

function CardHeader() {

    return (
        <header className="header">
            {/* <img style={{maxWidth: "80px"}} src="logo.png"  alt="AWET | Przychodnia weterynaryjna"></img> */}
        </header>
        
    )

}

export default CardHeader;